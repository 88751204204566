import React from "react";
import useStandardBreakpoints from "../hooks/useStandardBreakpoints";
import { H1 } from "../components/headings";
import Alert from "../components/Alert";

// structural components
import NarrowSection from "../components/structure/NarrowSection";
import { Grid, GridItem } from "../components/grids/NarrowGrid";

// form components
import FormField from "../components/forms/FormField";
import EmailForm from "../components/forms/EmailForm";
import SubmitButton from "../components/forms/SubmitButton";
import EmailField from "../components/forms/EmailField";
import FormError from "../components/forms/FormError";

const Contact = () => {
	const which = useStandardBreakpoints();

	const perRow =
		{
			xs: 1,
			sm: 1
		}[which] || 2;

	return (
		<>
			<NarrowSection>
				<H1>Contact</H1>
			</NarrowSection>
			<NarrowSection>
				<EmailForm
					renderSubmitted={() => (
						<Alert type="success">
							Success! Thanks for contacting me. I'll reply as
							soon as I can.
						</Alert>
					)}
				>
					<FormError />
					<Grid gutter={"10px"} perRow={perRow}>
						<GridItem>
							<FormField
								type="text"
								label="Name"
								name="name"
								required
							/>
						</GridItem>
						<GridItem>
							<FormField
								Field={EmailField}
								label="Email address"
								name="email"
								required
							/>
						</GridItem>
						<GridItem>
							<FormField
								type="textarea"
								label="Message"
								name="message"
							/>
						</GridItem>
					</Grid>
					<SubmitButton />
				</EmailForm>
			</NarrowSection>
		</>
	);
};

export default Contact;
