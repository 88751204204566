import React from "react";
import useStandardBreakpoints from "../hooks/useStandardBreakpoints";

// data
import projects from "../data/projects";

// components
import NarrowSection from "../components/structure/NarrowSection";
import { H1 } from "../components/headings";
import { Grid, GridItem } from "../components/grids/NarrowGrid";
import ProjectThumb from "../components/thumbs/ProjectThumb";

const Projects = () => {
	const which = useStandardBreakpoints();

	const perRow =
		{
			xs: 1
		}[which] || 2;

	return (
		<>
			<NarrowSection>
				<H1>Projects</H1>
			</NarrowSection>
			<NarrowSection>
				<Grid perRow={perRow}>
					{projects.map(project => (
						<GridItem>
							<ProjectThumb key={project.name} {...project} />
						</GridItem>
					))}
				</Grid>
			</NarrowSection>
		</>
	);
};

export default Projects;
