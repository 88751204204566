import React from "react";
import styled from "styled-components";

import bkstSiteHome from "../../images/projects/Bookstores/bkst_site_home.jpg";
import bkstSiteProduct from "../../images/projects/Bookstores/bkst_site_product.jpg";
import bkstSiteBooks from "../../images/projects/Bookstores/bkst_site_books.jpg";
import bkstSiteGamesHeader from "../../images/projects/Bookstores/bkst_site_games_header.jpg";
import bkstSiteMoviesHeader from "../../images/projects/Bookstores/bkst_site_movies_header.jpg";
import bkstSiteSellBooksHeader from "../../images/projects/Bookstores/bkst_site_sell-books_header.jpg";
import bkstSiteSellGamesHeader from "../../images/projects/Bookstores/bkst_site_sell-games_header.jpg";

import NarrowSection from "../../components/structure/NarrowSection";
import WideSection from "../../components/structure/WideSection";
import Hero from "../../components/images/Hero";
import { Grid, GridItem } from "../../components/grids/NarrowGrid";

import Emphasis from "../../components/Emphasis";
import FancyImage from "../../components/images/FancyImage";

import useStandardBreakpoints from "../../hooks/useStandardBreakpoints";

const P = styled.p``;

const BookstoresWebsite = () => {
	const which = useStandardBreakpoints();

	const perRow =
		{
			xs: 1,
			sm: 1,
			md: 1
		}[which] || 2;

	return (
		<>
			<NarrowSection>
				<P>
					<Emphasis>A long-neglected e-commerce website,</Emphasis>
					Bookstores.com was overdue for improvements in both
					aesthetics and functionality. While competitors had
					progressively moved toward a more modern design language,
					Bookstores.com looked like a thing of the past. In addition,
					inconsistent layout and style choices and an unwieldy color
					palette made for a fragmented and potentially confusing user
					experience.
				</P>
				<P>
					One of my first priorities was to take stock of the various
					visual pieces I had to work with - logos, icons, colors, and
					various graphical elements - and determine how best to use
					them in the context of a modern, well-organized,
					user-friendly website.
				</P>
				<P>
					After narrowing the unwieldy color palette down to a more
					manageable, better-matching set of 4, I gave each color a
					job on the site: blue, the dominant color in existing
					Bookstores.com branding and the most conservative of the
					colors, served as my primary; green (for “go”) was a good
					fit for links and buttons; and magenta proved useful as an
					accent color, used sparingly and for effect.
				</P>
			</NarrowSection>
			<WideSection>
				<Hero src={bkstSiteHome} />
			</WideSection>
			<NarrowSection>
				<P>
					<Emphasis>The other major priorities</Emphasis>
					were to infuse the site with a modern aesthetic, restructure
					the layouts to improve organization of information and ease
					of use, and generally elevate the user experience.
				</P>
			</NarrowSection>
			<WideSection>
				<Hero src={bkstSiteProduct} />
			</WideSection>
			<NarrowSection>
				<P>
					<Emphasis>
						The existing site had a lot of information
					</Emphasis>
					crammed into a small space, so I removed clutter and added
					white space for a more pleasant, open feel. Larger font
					sizes enhanced readability while clear delineations between
					heading styles made it easy to follow the basic structure of
					a page.
				</P>
				<P>
					Given the search-centric nature of e-commerce, I prioritized
					a large search bar at the top of department landing pages,
					set apart by a drop shadow and an engaging background photo:
				</P>
			</NarrowSection>
			<WideSection>
				<Hero src={bkstSiteBooks} />
			</WideSection>
			<NarrowSection>
				<P>
					<Emphasis>Each photo portrayed a potential user</Emphasis>
					in a realistic scenario related to the focus of that page:
				</P>
			</NarrowSection>
			<WideSection>
				<Grid perRow={perRow}>
					<GridItem>
						<FancyImage src={bkstSiteGamesHeader} />
					</GridItem>
					<GridItem>
						<FancyImage src={bkstSiteMoviesHeader} />
					</GridItem>
					<GridItem>
						<FancyImage src={bkstSiteSellBooksHeader} />
					</GridItem>
					<GridItem>
						<FancyImage src={bkstSiteSellGamesHeader} />
					</GridItem>
				</Grid>
			</WideSection>
		</>
	);
};

export default BookstoresWebsite;
