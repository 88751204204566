// Bookstores.com
import homeHeroBkst from "../images/home/home_hero_bkst_site.jpg";
import BookstoresBranding from "../pages/projects/BookstoresBranding";
import bkstLogoThumb from "../images/projects/projects_thumb_bkst_logo_2x.jpg";
import BookstoresWebsite from "../pages/projects/BookstoresWebsite";
import bkstSiteThumb from "../images/projects/projects_thumb_bkst_site_2x.jpg";

// K12 Book Services
import homeHeroK12 from "../images/home/home_hero_k12_flyers.jpg";
import K12Branding from "../pages/projects/K12Branding";
import k12FlyersThumb from "../images/projects/projects_thumb_k12_flyers_2x.jpg";
import K12Website from "../pages/projects/K12Website";
import k12SiteThumb from "../images/projects/projects_thumb_k12_site_2x.jpg";

// TextbookRush
import homeHeroRush from "../images/home/home_hero_rush_site.jpg";
import TextbookRushWebsite from "../pages/projects/TextbookRushWebsite";
import rushSiteThumb from "../images/projects/projects_thumb_rush_site_2x.jpg";
import TextbookRushBookmarks from "../pages/projects/TextbookRushBookmarks";
import rushBookmarksThumb from "../images/projects/projects_thumb_rush_bookmarks_2x.jpg";

export default [
	{
		name: "bookstores_branding",
		client: "Bookstores.com",
		project: "Brand development",
		color: "#25a7e9",
		colorRGB: "37,167,233",
		pageComponent: BookstoresBranding,
		thumbImage: bkstLogoThumb,
		thumbImageAlt: "Bookstores.com branding"
	},
	{
		name: "bookstores_website",
		client: "Bookstores.com",
		project: "Website redesign",
		color: "#25a7e9",
		colorRGB: "37,167,233",
		pageComponent: BookstoresWebsite,
		homeImage: homeHeroBkst,
		homeImageAlt: "Bookstores.com website",
		description:
			"A long-neglected e-commerce website, Bookstores.com was overdue for improvements in both aesthetics and functionality. While competitors had progressively moved toward a more modern design language, Bookstores.com looked like a thing of the past. In addition, inconsistent layout and style choices and an unwieldy color palette made for a fragmented and potentially confusing user experience.",
		bullets: ["Web Design", "HTML5", "CSS3, LESS", "JavaScript, jQuery"],
		thumbImage: bkstSiteThumb,
		thumbImageAlt: "Bookstores.com website"
	},
	{
		name: "k12_branding",
		client: "K12 Book Services",
		project: "Branding & print design",
		color: "#cb2031",
		colorRGB: "203,32,49",
		pageComponent: K12Branding,
		homeImage: homeHeroK12,
		homeImageAlt: "K12 Book Services branding",
		description:
			"For K12 Book Services, the name says it all. The company provides an array of services to K-12 schools with a focus on buying and selling books. I was asked to refresh their brand, blending the professionalism K12 Book Services had become known for with the more distinctive, student-focused personality they were seeking.",
		thumbImage: k12FlyersThumb,
		thumbImageAlt: "K12 Book Services branding"
	},
	{
		name: "k12_website",
		client: "K12 Book Services",
		project: "Website redesign",
		color: "#cb2031",
		colorRGB: "203,32,49",
		pageComponent: K12Website,
		thumbImage: k12SiteThumb,
		thumbImageAlt: "K12 Book Services website"
	},
	{
		name: "textbookrush_website",
		client: "TextbookRush",
		project: "Website redesign",
		color: "#81bc4b",
		colorRGB: "129,188,75",
		pageComponent: TextbookRushWebsite,
		homeImage: homeHeroRush,
		homeImageAlt: "TextbookRush website",
		description:
			"TextbookRush.com needed more than a facelift. Constructed piecemeal over the years as new features and products were launched and priorities changed, the textbook retailer’s e-commerce site needed a consistent, cohesive look - to be redesigned from a single, fresh perspective so customers were not disoriented by an unpredictable experience or turned off by unprofessional oddities and imperfections.",
		bullets: ["Web Design", "HTML5", "CSS3, LESS", "JavaScript, jQuery"],
		thumbImage: rushSiteThumb,
		thumbImageAlt: "TextbookRush website"
	},
	{
		name: "textbookrush_bookmarks",
		client: "TextbookRush",
		project: "Bookmarks",
		color: "#81bc4b",
		colorRGB: "129,188,75",
		pageComponent: TextbookRushBookmarks,
		thumbImage: rushBookmarksThumb,
		thumbImageAlt: "TextbookRush bookmarks"
	}
];
