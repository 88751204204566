import React from "react";
import styled from "styled-components";
import { mediaSingleProp } from "../../styles/utilities";

// excerpt components
import ExcerptContainer from "./ExcerptContainer";
import ExcerptHeader from "./ExcerptHeader";
import ExcerptHero from "./ExcerptHero";
import ExcerptButton from "./ExcerptButton";

// other components
import WideContent from "../structure/WideContent";
import NarrowContent from "../structure/NarrowContent";
import { Grid, GridItem } from "../grids/NarrowGrid";
import Bullet from "../Bullet";

const ExcerptHeroContainer = styled.div`
	margin-top: -20px;
	@media only screen and (max-width: 800px) {
		margin-top: -10px;
	}
`;

const DescriptionContainer = styled.div`
	margin-top: 50px;
	${mediaSingleProp("margin-top", [
		[1000, "40px"],
		[800, "30px"],
		[600, "20px"]
	])}
`;

const Description = styled.p`
	margin: 0 0 25px 0;
	@media only screen and (min-width: 801px) {
		line-height: 1.625;
	}
	@media only screen and (max-width: 800px) {
		margin-bottom: 20px;
	}
	@media only screen and (min-width: 601px) and (max-width: 800px) {
		line-height: 1.5625;
	}
`;

const ProjectExcerpt = ({
	client,
	project,
	color,
	colorRGB,
	homeImage,
	homeImageAlt,
	description,
	bullets = []
}) => (
	<ExcerptContainer>
		<ExcerptHeader client={client} project={project} color={color} />
		<WideContent>
			<ExcerptHeroContainer>
				<ExcerptHero src={homeImage} alt={homeImageAlt} />
			</ExcerptHeroContainer>
		</WideContent>
		<NarrowContent>
			<DescriptionContainer>
				<Grid>
					<GridItem flex="2 2">
						<Description>{description}</Description>
						<ExcerptButton
							color={color}
							colorRGB={colorRGB}
							to={`/projects/${project.name}`}
						>
							View project
						</ExcerptButton>
					</GridItem>
					<GridItem sm={{ hidden: true }}>
						{bullets.map((bullet, i) => (
							<Bullet key={i}>{bullet}</Bullet>
						))}
					</GridItem>
				</Grid>
			</DescriptionContainer>
		</NarrowContent>
	</ExcerptContainer>
);

export default ProjectExcerpt;
