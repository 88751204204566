import React from "react";
import styled from "styled-components";
import NarrowContent from "../structure/NarrowContent";
import { clear, mediaSingleProp } from "../../styles/utilities";
import Nav from "../bookends/Nav";
import RouterLink from '../RouterLink'

const Name = styled.div`
	float: left;
	color: ${({ color }) => color};
	transition: color 0.15s ease-in-out;
	font-weight: 700;
	@media only screen and (max-width: 600px) {
		color: #ffffff;
	}
`;

const Role = styled.div`
	float: right;
	color: ${({ color }) => color};
	transition: color 0.15s ease-in-out;
	font-weight: 400;
	@media only screen and (max-width: 600px) {
		display: none;
	}
`;

const Container = styled.div`
	padding: 25px 0;
	background-color: ${({ color }) => color};
	${mediaSingleProp("padding", [[600, "15px 0"]])}
`;

const Title = styled(RouterLink)`
	display: block;
	width: 100%;
	font-family: "Rajdhani", sans-serif;
	font-size: 44px;
	line-height: 1;
	text-transform: uppercase;
	:hover ${Name}, :hover ${Role}, :focus ${Name}, :focus ${Role} {
		color: ${({ hoverColor }) => hoverColor};
	}
	${clear}
	${mediaSingleProp("font-size", [[800, "36px"]])}
`;

const Header = ({ colors }) => (
	<Container color={colors.background}>
		<NarrowContent>
			<Title to="/" hoverColor={colors.hover}>
				<Name color={colors.name}>Josh Narwold</Name>
				<Role color={colors.role}>Web Designer</Role>
			</Title>
			<Nav colors={colors} />
		</NarrowContent>
	</Container>
);

Header.defaultProps = {
	colors: {
		background: "#1da7e7",
		hover: "#ffffff",
		name: "#a6e3ff",
		role: "#a6e3ff",
		border: "rgba(166,227,255,.4)",
		link: "#ffffff"
	}
};

export default Header;
