import React from "react";
import styled from "styled-components";

// components
import NarrowSection from "../components/structure/NarrowSection";
import { H1 } from "../components/headings";
import Emphasis from "../components/Emphasis";

const P = styled.p``;

const About = () => (
	<>
		<NarrowSection>
			<H1>About me</H1>
		</NarrowSection>
		<NarrowSection>
			<P>
				<Emphasis>Hi there! I’m Josh Narwold,</Emphasis> a Web Designer
				and Front-End Web Developer based out of the Columbus, Ohio
				area.
			</P>
			<P>
				As a young boy with artistic aspirations, I drew my own sports
				cards in pencil; wrote and designed newsletters about various
				subjects; and even tried my hand at auto body design, adding my
				own copy and graphics to make little ads in my sketchbook.
			</P>
			<P>
				Of course, at the time I wasn’t aware of this field of study
				that existed called “Graphic Design”. It was my parents who
				discovered that and encouraged me to pursue it, and I’m grateful
				for that because working as a designer fuels my passion for art.
			</P>
			<P>
				Today that passion manifests itself in my work as a Web
				Designer. Over seven years of experience in the field, I've
				produced print and web solutions (and branched out into
				implementing web languages including HTML5, CSS3,
				JavaScript/jQuery, and React.js), continually pouring myself
				into creating beautiful, functional art that serves the needs of
				both client and user.
			</P>
		</NarrowSection>
	</>
);

export default About;
