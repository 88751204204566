import React from "react";
import styled from "styled-components";
import { Link as RRLink } from "react-router-dom";

import NarrowSection from "../../components/structure/NarrowSection";
import WideSection from "../../components/structure/WideSection";
import Hero from "../../components/images/Hero";
import { Grid, GridItem } from "../../components/grids/WideGrid";

import bkstLogoOld from "../../images/projects/Bookstores/bkst_logo_old.png";
import bkstLogoNew from "../../images/projects/Bookstores/bkst_logo_new.png";
import bkstBooksMark from "../../images/projects/Bookstores/bkst_books-mark_2x.png";
import bkstBMark from "../../images/projects/Bookstores/bkst_b-mark_2x.png";

import Emphasis from "../../components/Emphasis";
import FancyImage from "../../components/images/FancyImage";

const P = styled.p``;
const Link = styled(RRLink)``;

const LogoHero = styled(Hero)`
	img {
	}
`;

const LogoOldHero = styled(LogoHero)`
	padding: 9% 9% 15% 9%;
	img {
		max-width: 800px;
	}
`;

const LogoNewHero = styled(LogoHero)`
	padding: 13% 9% 15% 9%;
	img {
		max-width: 900px;
	}
`;

const Swatch = styled.div`
	width: 100%;
	padding-top: 100%;
	border-radius: 50%;
	background-color: ${({ color }) => color};
`;

const Mark = styled(FancyImage)`
	padding: 18%;
	img {
		display: block;
		margin: 0 auto;
		width: 100%;
		max-width: 366px;
	}
`;

const BookstoresBranding = () => (
	<>
		<NarrowSection>
			<P>
				<Emphasis>In performing my initial research</Emphasis>
				prior to a{" "}
				<Link to="/projects/bookstores_site">
					Bookstores.com website redesign
				</Link>
				, I quickly discovered that the brand assets needed some
				refining. Here’s the existing logo:
			</P>
		</NarrowSection>
		<WideSection>
			<LogoOldHero src={bkstLogoOld} />
		</WideSection>
		<NarrowSection>
			<P>
				<Emphasis>Right off the bat, it was apparent</Emphasis>
				that the colors would be a problem. Typically in developing a
				brand, you want to limit the number of core colors to keep the
				look simple and the use of those colors manageable. While 6
				colors (the number in the Bookstores.com logo) isn’t necessarily
				a problem, the 6 colors used in this case didn’t all match one
				another.
			</P>
			<P>
				I removed two colors that clashed with the rest, the red and
				brown hues, leaving me with a set of four that blended together
				well but also offered enough variety that a lot could be done
				with them in various design applications.
			</P>
		</NarrowSection>
		<WideSection>
			<Grid>
				{["#5a5b5d", "#28a9e0", "#8dc642", "#b55b94"].map(color => (
					<GridItem>
						<Swatch color={color} />
					</GridItem>
				))}
			</Grid>
		</WideSection>
		<NarrowSection>
			<P>
				<Emphasis>
					The existing logo also suffered from a lack of balance
				</Emphasis>
				due to its “shelf of books” concept, which shifted the weight of
				the logo to the top right quadrant and consistently caused
				headaches for our design team as we tried to position elements
				around it.
			</P>
			<P>
				In addition, the “books” in the logo were portrayed abstractly
				enough (given their simple geometric shapes) that, apart from
				the obvious correlation with the word “bookstores” below, their
				status as books wasn’t obvious. If anything, given their
				vertical position and widely varying heights, they had the
				appearance of an audio equalizer.
			</P>
			<P>
				I moved the books to the left of the lettering and aligned the
				bottom of their spines with the bottom of the letters,
				maintaining the “sitting on a shelf” look while shifting the
				overall balance of the logo closer to its center.
			</P>
			<P>
				As I’d done with the color palette, I cut down on the number of
				books. Why display 13 books in a logo when you can convey the
				same concept with 3? I also tilted the third book in the set to
				clarify that these rectangles actually represented books. Here’s
				the updated logo:
			</P>
		</NarrowSection>
		<WideSection>
			<LogoNewHero src={bkstLogoNew} />
		</WideSection>
		<NarrowSection>
			<P>
				<Emphasis>I separated the books icon</Emphasis>to be used on its
				own as a secondary brand mark, a design element simple enough to
				fit in a variety of contexts but still recognizable as part of
				the logo. Rounding out the set was a condensed version of the
				logo that combined the “b” from the full logo with the books
				icon. This logo appeared in the footer of the redesigned
				website.
			</P>
		</NarrowSection>
		<WideSection>
			<Grid>
				<GridItem>
					<Mark src={bkstBooksMark} />
				</GridItem>
				<GridItem>
					<Mark src={bkstBMark} />
				</GridItem>
			</Grid>
		</WideSection>
	</>
);

export default BookstoresBranding;
