import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import k12SiteHome from "../../images/projects/K12BookServices/k12_site_home.jpg";
import k12SiteAbout from "../../images/projects/K12BookServices/k12_site_about.jpg";
import k12SiteBuyHeader from "../../images/projects/K12BookServices/k12_site_buy_header.jpg";
import k12SiteSellHeader from "../../images/projects/K12BookServices/k12_site_sell_header.jpg";
import k12SiteAboutHeader from "../../images/projects/K12BookServices/k12_site_about_header.jpg";
import k12SiteContactHeader from "../../images/projects/K12BookServices/k12_site_contact_header.jpg";

import NarrowSection from "../../components/structure/NarrowSection";
import WideSection from "../../components/structure/WideSection";
import Hero from "../../components/images/Hero";
import { Grid, GridItem } from "../../components/grids/NarrowGrid";

import Emphasis from "../../components/Emphasis";
import FancyImage from "../../components/images/FancyImage";

import useStandardBreakpoints from "../../hooks/useStandardBreakpoints";

const P = styled.p``;

const K12Website = () => {
	const which = useStandardBreakpoints();

	const perRow =
		{
			xs: 1,
			sm: 1,
			md: 1
		}[which] || 2;

	return (
		<>
			<NarrowSection>
				<P>
					<Emphasis>As part of a larger rebranding project</Emphasis>(
					<Link to="/projects/k12_branding">more on that here</Link>),
					K12 Book Services needed a new website. As a bookseller
					whose sales and customer service staff mostly interacted
					with school officials, they knew that professionalism was
					key; but they also wanted their new aesthetic to reflect the
					fact that, ultimately, the focus of both the company and the
					schools it serves is the children.
				</P>
			</NarrowSection>
			<WideSection>
				<Hero src={k12SiteHome} />
			</WideSection>
			<NarrowSection>
				<P>
					<Emphasis>
						Each of the design elements{" "}
						<Link to="/projects/k12_branding">
							established in the branding process
						</Link>
					</Emphasis>
					made its way onto the website in one form or another,
					forming a connection between K12’s online presence and the
					print materials I was designing.
				</P>
				<P>
					In keeping with the student theme, the background for the
					site was modeled after a sheet of ruled paper, with the
					iconic vertical pink line and horizontal light blue lines.
				</P>
				<P>
					Other background elements included a chalkboard texture
					(always colored red on the site) and a repeating school
					supplies pattern that was typically an overlay of the
					chalkboard texture (as in the footer and buttons shown
					above) but also appeared on its own (in the header, using
					the same blue as the ruled lines).
				</P>
			</NarrowSection>
			<WideSection>
				<Hero src={k12SiteAbout} />
			</WideSection>
			<NarrowSection>
				<P>
					<Emphasis>
						Fredoka One, by Google Fonts, served as the display font
					</Emphasis>
					- a perfect match for the bold style of typography I wanted
					to employ. It toes the line between professional and playful
					thanks to its geometric, oversized lines and their rounded
					corners.
				</P>
				<P>
					Each page on this small site shared a common design for its
					main heading: a short bit of text with one key word carrying
					the visual emphasis and a corresponding, full-width
					background photo overlaid by a tint of K12 Blue.
				</P>
			</NarrowSection>
			<WideSection>
				<Grid perRow={perRow}>
					<GridItem>
						<FancyImage src={k12SiteBuyHeader} />
					</GridItem>
					<GridItem>
						<FancyImage src={k12SiteSellHeader} />
					</GridItem>
					<GridItem>
						<FancyImage src={k12SiteAboutHeader} />
					</GridItem>
					<GridItem>
						<FancyImage src={k12SiteContactHeader} />
					</GridItem>
				</Grid>
			</WideSection>
		</>
	);
};

export default K12Website;
