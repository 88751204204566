import React, { useContext } from "react";
import styled from "styled-components";
import { mediaSingleProp } from "../../styles/utilities";
import StatusContext from "./StatusContext";
import Alert from "../Alert";

/*const ErrorMsg = styled.p`
	width: 100%;
	margin: 0 0 70px 0;
	padding: 10px 15px;
	background-color: rgba(244, 67, 54, 0.1);
	border: 1px solid rgba(244, 67, 54, 0.3);
	border-radius: 7px;
	color: #f44336;
	font-weight: 400;
	${mediaSingleProp("margin-bottom", [
		[1000, "50px"],
		[800, "40px"],
		[450, "30px"]
	])}
	@media only screen and (max-width: 800px) {
		padding: 8px 12px;
	}
`;*/

const FormError = () => {
	const { errorMsg } = useContext(StatusContext);
	return errorMsg ? <Alert type="error">{errorMsg}</Alert> : null;
};

export default FormError;
