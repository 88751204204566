import React from "react";
import projects from "../data/projects";

// components
import Header from "../components/bookends/Header";
import ProjectExcerpt from "../components/excerpts/ProjectExcerpt";
import Footer from "../components/bookends/Footer";

const Home = () => (
	<>
		<Header />
		{projects
			.filter(({ homeImage }) => homeImage)
			.map(project => (
				<ProjectExcerpt {...project} />
			))}
		<Footer />
	</>
);

export default Home;
